// https://divdev.io/animating-gatsby-pt/
import React from 'react'

import SEO from '../components/seo'
import * as commonPageStyles from '../styles/common-page.module.css'

const NaukaGryNaGitarzePage = () => (
  <>
    <SEO title="Nauka gry na gitarze Jaworzno - Music Spot"
    description="Zapraszamy na profejsonalną naukę gry na gitarze w Jaworznie, prowadzoną pod okiem profesjonalnych oraz doświadczonych muzyków. Zapoznaj się z naszą ofertą i zapisz się już dziś!" />
    <section className="sub-page-header" style={{ padding: '55px' }}>
      <div className="wrapper">
        <h1>Nauka Gry na gitarze Jaworzno</h1>
      </div>
    </section>

    <div className={`${commonPageStyles.sectionsContainer} wrapper`}>
      <section>
        <h2>Oferta Music Spot skierowana jest do osób:</h2>
        <p>
          <li>
            początkujących, pragnących rozpocząćnaukę gry na gitarze bez
            wcześniejszego przygotowania
          </li>
          <li>wdzieci i młodzieży w wieku szkolnym</li>
          <li>
            osób z doświadczeniem; zarówno hobbystów jak i profesjonalistów
          </li>
          <li>osób chcących przygotować się do egzaminów na studia muzyczne</li>
        </p>
      </section>

      <section>
        <h3>Przebieg lekcji gry na gitarze:</h3>

        <p>
          Program realizowany podczas lekcji gitary dostosowany jest do danego
          ucznia. Naszym celem jest dbanie, aby uczeń przyswajał odpowiedni dla
          siebie w danym momencie materiał pod kątem trudności. Często adepci
          gry na gitarze rezygnują, gdyż zabierają się za zbyt trudny, bądź zbyt
          łatwy materiał, przez co brakuje im motywacji.W trakcie lekcji gry na
          gitarze punktem wyjścia jest dla nas repertuar. Nauka utworów oprócz
          budowania własnego repertuaru jest doskonałym laboratorium pracy nad
          techniką, ale także innymi elementami muzycznymi. Każdy utwór
          reprezentuje odrębne zagadnienia z zakresu m.in. harmonii czy rytmu,
          które będą zgłębianie podczas lekcji gry na gitarze.{' '}
        </p>
      </section>

      <section>
        <h3>Technika gry na gitarze</h3>

        <p>
          Technikę gry na gitarze poznajemy w oparciu o zrozumienie jak działa
          nasze ciało i jak najefektywniej z niego korzystać w celu uzyskania
          dobrego brzmienia, precyzji rytmicznej oraz komfortu gry. Podczas
          lekcji gitary dbamy o eliminowanie złych nawyków i nabranie nowych
          przyzwyczajeń, jak również pokazujemynowe możliwości rozwoju.Dużym
          wsparciem w trakcie nauki gry na gitarze jest wiedza z zakresu
          Techniki Alexandra, która skupia się na zrozumieniu jak działają nasze
          mięśnie, gdzie gromadzą się napięcia.Istotą pracy nad techniką podczas
          lekcji gry na gitarze, jest świadomość, iż technika nie jest celem
          samym w sobie, lecz środkiem umożliwiającym swobodne muzykowanie.
        </p>
      </section>

      <section>
        <h3>Nauka gry na gitarze ze słuchu</h3>

        <p>
          Podczas lekcji gry na gitarze staramy się przekazywać wiedzę w sposób
          naturalny, zbliżony do nauki języka ojczystego. Nie rozpoczynamy nauki
          od korzystania z książek, lecz opieramy się na odwzorowywaniu języka
          muzycznego naszych mistrzów. Nie robimy tego jednak po to, by kopiować
          naszych ulubionych muzyków, ale by swobodnie operować poznanym
          językiem podczas współpracy z innymi muzykami oraz by ukształtować i
          wzbogacić własny styl muzyczny.
        </p>
      </section>

      <section>
        <h3>Improwizacja</h3>

        <p>
          Najczęstszą motywacją do rozpoczęcianauki gry na gitarze jest chęć
          swobodnego improwizowania. Podstawą improwizacji jest dobrze
          wykształcony słuch, nad którego rozwojem pracujemy podczas zajęć gry
          na gitarze. Dopełnieniem przygotowania do improwizacji jest wiedza z
          zakresu harmonii oraz zasad muzyki. Takie przygotowanie pozwoli na
          łatwe i swobodne poruszanie się w warstwę muzycznej danych utworów.{' '}
        </p>
      </section>

      <section>
        <h3>Lekcje gry na gitarze dla dzieci</h3>

        <p>
          Music Spot to miejsce nie tylko dla młodzieży i dorosłych. Uczymy
          również dzieci, stanowiąc doskonałą alternatywę dla państwowych szkół
          muzycznych. Dzięki indywidualnemu podejściu, a nie z góry narzuconemu
          programowi, łatwiej nam dotrzeć do każdego ucznia i wydobyć jego
          potencjał twórczy. Nauka gry na gitarze w Music Spot to szansa na
          rozwój kreatywności oraz poszerzenie horyzontów muzycznych.
        </p>
        <section style={{ marginTop: '70px', marginBottom: '50px' }}>
          <p>
            Serdecznie zapraszamy dzieci, młodzież i dorosłych - mieszkańców
            miast: Jaworzno, Chrzanów, Libiąż, Olkusz, Katowice, Trzebinia,
            Imielin, Mysłowice, Sosnowiec, a także miejscowości położonych w
            dalszych rejonach.{' '}
          </p>
        </section>
      </section>
    </div>
  </>
)

export default NaukaGryNaGitarzePage
